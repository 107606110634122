import "./../../../styles/Skill.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCode,
  faEarthAmericas,
  faBrain,
  faStar,
  faDatabase,
  faGlobe,
  faDesktop,
  faXmark,
  faPerson,
  faPalette,
  faPeopleGroup,
  faGem,
} from "@fortawesome/free-solid-svg-icons";
import {
  faHtml5,
  faCss3Alt,
  faBootstrap,
  faSass,
  faPhp,
  faSymfony,
  faWordpress,
  faJava,
  faJs,
  faReact,
  faLinux,
  faWindows,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import {
  faFileWord,
  faFileText,
  faClock,
  faHand,
} from "@fortawesome/free-regular-svg-icons";
import technologyImg from "./../../../assets/images/techology.svg";
import booksImg from "./../../../assets/images/books.svg";
import laptop from "./../../../assets/images/laptop.svg";
import french from "./../../../assets/images/lang/french.png";
import english from "./../../../assets/images/lang/english.png";
import spanish from "./../../../assets/images/lang/spanish.png";

import { useState } from "react";

function techCard(icon, tech, type, level) {
  var color = "primary";
  var color2 = "info";
  if (type === "lang") {
    color = "primary";
    color2 = "info";
  } else if (type === "library") {
    color = "info";
    color2 = "info";
  } else if (type === "framework") {
    color = "danger";
    color2 = "warning";
  } else if (type === "cms") {
    color = "warning";
    color2 = "warning";
  } else if (type === "other") {
    color = "primary-dark";
  }
  function RenderStar(level) {
    let starArray = [];
    if (type !== "other" && type !== "other2") {
      for (let i = 0; i < 5; i++) {
        if (i < level) {
          starArray.push(
            <FontAwesomeIcon
              key={tech + i}
              className={"text-" + color2}
              icon={faStar}
            />
          );
        } else {
          starArray.push(
            <FontAwesomeIcon
              key={tech + i}
              className="text-secondary"
              icon={faStar}
            />
          );
        }
      }
    }
    return starArray;
  }
  return (
    <div className="bg-light card border-0 me-2 mb-2 tech-card shadow-sm">
      <div className="card-body text-center">
        <FontAwesomeIcon className={"text-" + color + " fs-1"} icon={icon} />
        <p className="text-secondary fs-5 my-1">{tech}</p>
        {RenderStar(level)}
      </div>
    </div>
  );
}
function personnalityCard(icon, tech, description) {
  return (
    <div className="bg-light card border-0 shadow-sm perso-card me-2 mb-2">
      <div className="card-body text-center">
        <FontAwesomeIcon className={"text-success fs-1 m-auto"} icon={icon} />
        <p className={"text-center text-secondary fs-5 my-1"}>{tech}</p>
      </div>
    </div>
  );
}
function langCard(img, tech, levelW, levelO) {
  return (
    <div className="bg-light card border-0 me-2 mb-2 shadow-sm lang-card">
      <div className="card-body text-center">
        <img src={img} className="img-fluid" alt="technology" />
        <p className="text-secondary fs-5 my-1">{tech}</p>
        <p>
          <strong>Niveau : </strong>
          {levelW}
          <br />
        </p>
      </div>
    </div>
  );
}

function Skill() {
  const [isSelectedTag, tagClick] = useState(false);
  return (
    <section className="page-section skill bg-primary bg-opacity-25" id="skill">
      <div className="container">
        <h2 className="page-section-heading text-center text-uppercase text-primary mb-5">
          Compétence
        </h2>
        <div className="row justify-content-center">
          <h3 className="text-primary mb-4 col-12 d-none d-lg-block">
            <FontAwesomeIcon icon={faCode} /> Technologies
          </h3>
          <div className="col-12 col-lg-8 mb-5">
            <div className="d-flex justify-content-center justify-content-lg-start flex-wrap">
              {techCard(faHtml5, "HTML", "lang", 5)}
              {techCard(faCss3Alt, "CSS", "lang", 5)}
              {techCard(faSass, "SASS", "lang", 3)}
              {techCard(faPhp, "PHP", "lang", 4)}
              {techCard(faJava, "Java", "lang", 4)}
              {techCard(faJs, "JavaScript", "lang", 3)}
              {techCard(faDatabase, "SQL", "lang", 4)}
              {techCard(faCode, "Dart", "lang", 2)}
              {techCard(faBootstrap, "Bootstrap", "library", 4)}
              {techCard(faSymfony, "Symfony", "framework", 4)}
              {techCard(faJava, "Spring Batch", "framework", 3)}
              {techCard(faJava, "Spring MVC", "framework", 3)}
              {techCard(faReact, "React js", "framework", 2)}
              {techCard(faJs, "Cordova", "framework", 2)}
              {techCard(faCode, "Flutter", "framework", 2)}
              {techCard(faWordpress, "WordPress", "cms", 4)}
            </div>
          </div>
          {isSelectedTag ? (
            <div className="col-12 col-lg-4 mb-5 pb-3">
              <div className="bg-light card mb-3  h-100 border-0">
                <div className="bg-light  bg-opacity-25 card-header text-center text-secondary border-0">
                  <div className="text-end">
                    <FontAwesomeIcon
                      onClick={() => tagClick(false)}
                      className="text-dark fs-3 c-pointor"
                      icon={faXmark}
                    />
                  </div>
                  <div>[LOGO]</div>
                  <h3 className="text-primary my-0">Technologie X</h3>
                </div>
                <div className="card-body">
                  <p className="mb-1">
                    <strong>Autre versions : </strong> x-1, x-2, x-3
                  </p>
                  <p className="mb-1">
                    <strong>Niveau : </strong>
                    <FontAwesomeIcon className="text-primary" icon={faStar} />
                    <FontAwesomeIcon className="text-primary" icon={faStar} />
                    <FontAwesomeIcon className="text-primary" icon={faStar} />
                    <FontAwesomeIcon className="text-primary" icon={faStar} />
                    <FontAwesomeIcon className="text-secondary" icon={faStar} />
                  </p>
                  <p>
                    <strong>Expérience : </strong> 4 ans
                  </p>
                  <ul>
                    <li>Caractérisque 1</li>
                    <li>Caractérisque 2</li>
                    <li>Caractérisque 3</li>
                    <li>Caractérisque 4</li>
                    <li>Caractérisque 5</li>
                  </ul>
                  <p className="mb-0">
                    Nunc lobortis, magna quis blandit efficitur, odio nisl
                    pharetra sem
                  </p>
                </div>
                {/* <div class="card-footer">
                  Difficile d'être mauvais en html
                </div> */}
              </div>
            </div>
          ) : (
            <div className="col-4 m-auto text-center d-none d-lg-block mb-xl-5">
              <img src={technologyImg} className="img-fluid" alt="technology" />
            </div>
          )}
          <div className="col-4 m-auto text-center d-none d-lg-block">
            <img src={laptop} className="img-fluid" alt="technology" />
          </div>
          <div className="col-12 col-lg-8 mb-5">
            <div className="d-flex flex-wrap justify-content-center justify-content-lg-end">
              {techCard(faWindows, "Windows", "other", 4)}
              {techCard(faLinux, "Linux", "other", 3)}
              {techCard(faFileWord, "Pack office", "other", 4)}
              {techCard(faFileText, "Libre office", "other", 4)}

              {techCard(faGithub, "Github", "other", 5)}
              {techCard(faGlobe, "Mantis", "other", 5)}
              {techCard(faDesktop, "FileZilla", "other", 5)}
              {techCard(faDesktop, "Visual studio", "other", 5)}
            </div>
          </div>
          <div className="d-none d-lg-block col-6 col-lg-8 my-3">
            <h3 className="text-primary mb-3">
              <FontAwesomeIcon icon={faBrain} /> Savoir-être
            </h3>
            <div className="d-flex flex-wrap">
              {personnalityCard(
                faPerson,
                "Autonome",
                "In accumsan semper eros quis pulvinar. Vivamus ut mauris dui"
              )}
              {personnalityCard(
                faClock,
                "Rigoureux",
                "Suspendisse at eros fermentum, faucibus nisl vel, auctor leo"
              )}
              {personnalityCard(
                faPalette,
                "Créatif",
                "Nullam viverra sem at quam interdum tristique"
              )}
              {personnalityCard(
                faPeopleGroup,
                "Coopératif",
                "Integer lobortis dui dolor, vel interdum lacus faucibus pretium"
              )}
              {personnalityCard(
                faHand,
                "Proacif",
                "Sed pharetra porttitor eros, sed dapibus purus"
              )}
              {personnalityCard(
                faGem,
                "Curieux",
                "Sed pharetra porttitor eros, sed dapibus purus"
              )}
            </div>
            <h3 className="text-primary my-3">
              <FontAwesomeIcon icon={faEarthAmericas} /> Langues
            </h3>
            <div className="d-flex flex-wrap">
              {langCard(french, "Français", "Natif")}
              {langCard(english, "Anglais", "Courant", 3)}
              {langCard(spanish, "Espagnol", "Notion", 2)}
            </div>
          </div>
          <div className="col-4 text-center d-none d-lg-block">
            <img src={booksImg} className="img-fluid" alt="technology" />
          </div>
        </div>
      </div>
    </section>
  );
}
export default Skill;
