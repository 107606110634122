import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDesktop } from "@fortawesome/free-solid-svg-icons";
import { faTabletScreenButton } from "@fortawesome/free-solid-svg-icons";
import { faArrowPointer } from "@fortawesome/free-solid-svg-icons";
import { faGears } from "@fortawesome/free-solid-svg-icons";
import "./../../../styles/Service.css";
import webDevelopment from "./../../../assets/images/service/web-development.svg";
// import seo from "./../../../assets/images/service/seo.svg";
// import responsive from "./../../../assets/images/service/responsive.svg";
import document from "./../../../assets/images/service/document.svg";
import hanshake from "./../../../assets/images/service/handshake.svg";

function serviceCard(img, title, subtitle, com, type) {
  return (
    <div className="col-12 mb-3">
      <div className={"bg-white card h-100 service-card shadow-sm " + type}>
        <div className="card-body text-center row">
          <div className="me-4 w-25 col-4 d-none d-md-flex me-5">
            <img className="img-fluid w-100 m-auto" src={img} alt="test" />
          </div>
          <div className="col-12 col-md-8 text-start">
            <h3 className="text-primary my-4">
              <img
                className="me-2 d-none d-sm-inline d-md-none"
                src={img}
                alt="test"
              />
              {title}
            </h3>
            <ul className="list-unstyled text-start mt-3">
              <li>
                <p className="text-primary-special fw-bold fs-6 mb-0">
                  {subtitle[0]}
                </p>
                <p className="text-secondary fs-6">{com[0]}</p>
              </li>
              <li>
                <p className="text-primary-special fw-bold fs-6 mb-0">
                  {subtitle[1]}
                </p>
                <p className="text-secondary fs-6">{com[1]}</p>
              </li>
              <li>
                <p className="text-primary-special fw-bold fs-6 mb-0">
                  {subtitle[2]}
                </p>
                <p className="text-secondary fs-6">{com[2]}</p>
              </li>
              <li>
                <p className="text-primary-special fw-bold fs-6 mb-0">
                  {subtitle[3]}
                </p>
                <p className="text-secondary fs-6">{com[3]}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

function Service() {
  return (
    <section
      className="page-section portfolio bg-primary-special bg-opacity-25 h-100"
      id="service"
    >
      <div className="container">
        <h2 className="page-section-heading text-center text-uppercase text-primary mb-5">
          Services
        </h2>
        <div className="row justify-content-center">
          {serviceCard(
            webDevelopment,
            "Développement web",
            [
              "Création de sites webs dynamiques",
              "Responsive design",
              "Référencement SEO",
              "Maintenance applicative",
            ],
            [
              "Création de site web avec des interactions avec une (ou plusieurs) base(s) de données.",
              "Conception de site capable de s'adapter à differentes tailles d'ecrans.",
              "Capacité de faire un site web bien réferencé par les moteurs de recherches",
              "Garantir la performance, la sécurité et la fiabilité des applications informatiques tout au long de leur cycle de vie",
            ]
          )}
          {serviceCard(
            document,
            "Rédaction documentaire",
            [
              "Documentation technique",
              "Cahier des charges",
              "Procédures",
              "Cahier de recette",
            ],
            [
              "Rédaction de documents clairs des spécifications techniques",
              "Conception d'un cahier des charges selon les besoins des clients.",
              "Rédaction de fiches afin de formaliser et standardiser les pratiques",
              "Documentation des différentes étapes de validation et de vérification des fonctionnalités",
            ]
          )}
          {serviceCard(
            hanshake,
            "Support utilisateur",
            [
              "Communication",
              "Résolution de problèmes",
              "Gestion des outils de support",
              "Formation des utilisateurs",
            ],
            [
              "Capacité à communiquer clairement et efficacement avec les utilisateurs",
              "Capacité à identifier, diagnostiquer et résoudre les problèmes techniques et fonctionnels rencontrés par les utilisateurs",
              "Capacité à utiliser les outils de support (Mantis, Jira, e-mail)",
              "Capacité à former les utilisateurs sur les fonctionnalités et les bonnes pratiques des produits et des services",
            ]
          )}
        </div>
      </div>
    </section>
  );
}
export default Service;
