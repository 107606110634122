import "./../../styles/AchievementCard.css";
import { Link } from "react-router-dom";

function AchievementCard({ id, title, desc, src, tech }) {
  return (
    <Link className="text-decoration-none" to={"/achievement/" + id}>
      <div
        className="achievementCard portfolio-item mx-auto rounded justify-content-end flex-column d-flex"
        style={{ backgroundImage: `url(${src})` }}
      >
        <div className="portfolio-item-caption d-flex align-items-center flex-column justify-content-end h-100 w-100">
          <div className="bg-dark text-center bg-opacity-75 w-100">
            <h2 className="mt-1 mb-1 text-light fs-5">{title}</h2>
            <p className="text-light mb-1">{desc}</p>
            <div className="text-center fs-6 mb-1">
              {tech.map((value, index) => (
                <span key={index} className="badge bg-light text-primary m-1">
                  {value.tech}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default AchievementCard;
