import { Link } from "react-router-dom";
import "./../../styles/Header.css";

function Header() {
  return (
    <header>
        <nav className="navbar navbar-expand-lg navbar-white bg-white shadow-sm">
            <div className="container">
              <Link className="navbar-brand link-primary" to={"/"}>Andy Faussau</Link>
                {/* <a className="navbar-brand link-primary" href="#!">Andy Faussau</a> */}
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                          <Link className="nav-link link-primary" to={"/"}>Accueil</Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link link-primary" to={"/achievement"}>Réalisations</Link>
                        </li>
                        {/* <li className="nav-item">
                          <Link className="nav-link link-primary" to={"/blog"}>Blog</Link>
                        </li>
   
                        <li className="nav-item">
                          <Link className="nav-link link-primary" to={"/admin"}>Admin</Link>
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav>
    </header>
  );
}

export default Header;
