import "./../../../styles/NavPortfolio.css";
function NavPortfolio() {
  return (
    <nav className="navbar navbar-expand-lg bg-white shadow text-uppercase fixed-top" id="mainNav">
       <div className="container">
                <a className="navbar-brand text-primary" href="#page-top">Andy Faussau</a>
                <button className="navbar-toggler text-uppercase font-weight-bold bg-primary text-white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    Menu
                    <i className="fas fa-bars"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav ms-auto" id="navbarResponsive2">
                        <li className="nav-item mx-0 mx-lg-1"><a className="nav-link py-3 px-0 px-lg-3 rounded-0" href="#about">A propos</a></li>
                        <li className="nav-item mx-0 mx-lg-1"><a className="nav-link py-3 px-0 px-lg-3 rounded-0" href="#portfolio">Réalisation</a></li>
                        <li className="nav-item mx-0 mx-lg-1"><a className="nav-link py-3 px-0 px-lg-3 rounded-0" href="#service">Service</a></li>
                        <li className="nav-item mx-0 mx-lg-1"><a className="nav-link py-3 px-0 px-lg-3 rounded-0" href="#skill">Compétence</a></li>
                        {/* <li className="nav-item mx-0 mx-lg-1"><a className="nav-link py-3 px-0 px-lg-3 rounded-0" href="#career" data-to-scrollspy-id="career">Parcours</a></li> */}
                        <li className="nav-item mx-0 mx-lg-1"><a className="nav-link py-3 px-0 px-lg-3 rounded-0" href="#contact">Contact</a></li>
                    </ul>
                </div>
            </div>
    </nav>
  );
}
export default NavPortfolio;
