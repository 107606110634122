import imgPortfolio from "./../assets/images/projet/portfolio/portfolio-img.png";

export const portfolioData = [
  {
    id: 0,
    title: "Mon portfolio",
    shortDescription: "Le site où vous êtes actuellement",
    generalDescription:
      "Mon portfolio a été développé en utilisant la technologie React, ce qui me permet de créer une interface utilisateur dynamique et interactive. Grâce à ce site, j'ai la possibilité de présenter mes projets de manière claire et organisée, en mettant en valeur mes compétences et mes réalisations. De plus, la création de ce portfolio m'a permis d'acquérir de nouvelles connaissances en matière de développement web et d'explorer de nouveaux outils et techniques",
    purposeTitle: "Ma vitrine professionnelle",
    purpose: [
      "J'ai créé mon portfolio pour plusieurs raisons. Tout d'abord, j'avais besoin d'augmenter ma visibilité. Avoir un portfolio est donc une excellente façon de montrer mes compétences et mes réalisations de manière convaincante et professionnelle",
      "En créant mon portfolio, je peux également montrer mes compétences à un public plus large et plus efficacement. Grâce à mon portfolio, je peux non seulement mettre en avant mes projets les plus réussis, mais aussi les différentes compétences que j'ai acquises au fil du temps.",
      "Enfin, j'avais besoin d'un projet pour utiliser React js que j'ai récemment apris en autodidacte. En utilisant cette technologie dans mon portfolio, je peux mettre en pratique mes nouvelles.",
    ],
    functioningTitle: "Accueil et réalisations",
    functioning:
      "Le site internet est organisé en deux sections distinctes : la page principale et la partie réalisation. La page principale regroupe les informations générales sur mes compétences ainsi que mes meilleurs projets. Quant à la partie réalisation, elle est consacrée à la présentation détaillée de tous mes projets.",
    conceptionTitle: "React JS et Symfony : une architecture efficace",
    conception: [
      "Le front-end (la partie visible) a été construit avec react js, react-router, boostrap et Sass.",
      "Le back-end (la partie non visible) a été construit avec Symfony 6 avec API platform." +
        " Le back end est donc une API",
    ],
    dateCreation: "15/05/2023",
    technologies: [
      { tech: "HTML", type: "Language" },
      { tech: "CSS", type: "Language" },
      { tech: "PHP", type: "Language" },
      { tech: "JavaScript", type: "Language" },
      { tech: "MySQL", type: "Language" },
      { tech: "Bootstrap", type: "Library" },
      { tech: "Symfony", type: "Framework" },
      { tech: "React js", type: "Framework" },
    ],
    devises: [{ tech: "Web", type: "Language" }],
    images: [{ url: imgPortfolio, alt: "mon portfolio" }],
    link: [
      { link: "https://www.andy-faussau.fr", type: "Vers le site" },
      { link: "/achievement/0", type: "Plus de détails" },
    ],
  },
];

// #[ORM\OneToMany(mappedBy: 'idAchievement', targetEntity: Link::class, orphanRemoval: true)]
// private Collection $links;
