import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
// import "./../../styles/AchievementCard.css";
import { Link } from "react-router-dom";

function SideWidget({ id, title, desc, src }) {
  return (
    <div className="card mb-4">
      <div className="card-header">Side Widget</div>
      <div className="card-body">
        Des widgets arriveront bientôt
      </div>
    </div>
  );
}

export default SideWidget;
