import Header from "./components/header";
import Footer from "./components/footer";
import "./../styles/Blog.css";
import { useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useState } from "react";
import SearchWidget from "./components/searchWidget";
import CategoryWidget from "./components/categoryWidget";
import SideWidget from "./components/sideWidget";
import { portfolioData } from "../datas/portfolioData";

function AchievementItem() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [techData, setTech] = useState([]);
  const [deviseData, setDevise] = useState([]);
  const [imageData, setImage] = useState([]);
  const [purposeData, setPurpose] = useState([]);
  const [conceptionData, setConception] = useState([]);

  useEffect(() => {
    // axios
    //   .get("http://127.0.0.1:8000/api/achievements/" + id)
    //   .then((response) => {
    //     const items = response.data;
    //     setData(items);

    //     const itemsTech = items.technologies;
    //     const promisesTech = itemsTech.map((url) => axios.get("http://127.0.0.1:8000" + url));
    //     Promise.all(promisesTech).then((results) => {
    //       setTech(results);
    //     });

    //     const itemsDevise = items.devises;
    //     const promisesDevise = itemsDevise.map((url) => axios.get("http://127.0.0.1:8000" + url));
    //     Promise.all(promisesDevise).then((results) => {
    //       setDevise(results);
    //     });

    //     const itemsImage = items.images;
    //     const promisesImage = itemsImage.map((url) => axios.get("http://127.0.0.1:8000" + url));
    //     Promise.all(promisesImage).then((results) => {
    //       setImage(results);
    //     });

    //   })
    //   .catch((error) => console.error(error));
    setData(portfolioData[0]);
    setTech(portfolioData[0].technologies);
    setDevise(portfolioData[0].devises);
    setImage(portfolioData[0].images[0]);
    setPurpose(portfolioData[0].purpose);
    setConception(portfolioData[0].conception)

  }, [id]);
  useEffect(() => {
    document.title = data.title +" - Andy Faussau";
  }, [data]);
  return (
    <div>
      <Header />
      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-8">
            <article>
              <div className="mb-4">
                <h1 className="fw-bolder mb-1 text-primary">{data.title}</h1>
                <div className="text-muted fst-italic mb-2">
                  Posté le {data.dateCreation}
                </div>
                {deviseData.map((value, index) => (
                  <span
                    key={"deviseData-" + index}
                    className="badge bg-danger text-decoration-none link-light me-1"
                    href="#!"
                  >
                    {value.tech}
                    {/* {value.data.tech} */}
                  </span>
                ))}
                {techData.map((value, index) => (
                  <span
                    key={"techData-" + index}
                    className="badge bg-primary text-decoration-none link-light me-1"
                    href="#!"
                  >
                    {value.tech}
                  </span>
                ))}
              </div>
              <figure className="mb-4">
                <img
                  className="img-fluid rounded"
                  src={imageData.url}
                  alt={imageData.alt}
                  // src={"https://dummyimage.com/900x400/ced4da/6c757d.jpg"}
                  // src={imageData[0].data.url}
                  // alt={imageData[0].data.alt}
                />
              </figure>
              <section className="mb-5">
                <p className="fs-5 mb-4 text-secondary">{data.generalDescription}</p>
                <h2 className="fw-bolder mb-4 mt-5">{data.purposeTitle}</h2>
                <p className="fs-5 mb-4">{purposeData[0]}</p>
                <p className="fs-5 mb-4">{purposeData[1]}</p>
                <p className="fs-5 mb-4">{purposeData[2]}</p>

                <h2 className="fw-bolder mb-4 mt-5">{data.functioningTitle}</h2>
                <p className="fs-5 mb-4">{data.functioning}</p>
                <h2 className="fw-bolder mb-4 mt-5">{data.conceptionTitle}</h2>
                <p className="fs-5 mb-4">{conceptionData[0]}</p>
                <p className="fs-5 mb-4">{conceptionData[1]}</p>

              </section>
            </article>
            {/* <section className="mb-5">
              <div className="card bg-light">
                <div className="card-body">
                  <form className="mb-4">
                    <textarea
                      className="form-control"
                      rows="3"
                      placeholder="Join the discussion and leave a comment!"
                    ></textarea>
                  </form>
                  <div className="d-flex mb-4">
                    <div className="flex-shrink-0">
                      <img
                        className="rounded-circle"
                        src="https://dummyimage.com/50x50/ced4da/6c757d.jpg"
                        alt="..."
                      />
                    </div>
                    <div className="ms-3">
                      <div className="fw-bold">Commenter Name</div>
                      If you're going to lead a space frontier, it has to be
                      government; it'll never be private enterprise. Because the
                      space frontier is dangerous, and it's expensive, and it
                      has unquantified risks.
                      <div className="d-flex mt-4">
                        <div className="flex-shrink-0">
                          <img
                            className="rounded-circle"
                            src="https://dummyimage.com/50x50/ced4da/6c757d.jpg"
                            alt="..."
                          />
                        </div>
                        <div className="ms-3">
                          <div className="fw-bold">Commenter Name</div>
                          And under those conditions, you cannot establish a
                          capital-market evaluation of that enterprise. You
                          can't get investors.
                        </div>
                      </div>
                      <div className="d-flex mt-4">
                        <div className="flex-shrink-0">
                          <img
                            className="rounded-circle"
                            src="https://dummyimage.com/50x50/ced4da/6c757d.jpg"
                            alt="..."
                          />
                        </div>
                        <div className="ms-3">
                          <div className="fw-bold">Commenter Name</div>
                          When you put money directly to a problem, it makes a
                          good headline.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="flex-shrink-0">
                      <img
                        className="rounded-circle"
                        src="https://dummyimage.com/50x50/ced4da/6c757d.jpg"
                        alt="..."
                      />
                    </div>
                    <div className="ms-3">
                      <div className="fw-bold">Commenter Name</div>
                      When I look at the universe and all the ways the universe
                      wants to kill us, I find it hard to reconcile that with
                      statements of beneficence.
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
          </div>
          <div className="col-lg-4">
            {/* <SearchWidget />
            <CategoryWidget /> */}
            <SideWidget />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default AchievementItem;
