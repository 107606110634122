import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGamepad } from "@fortawesome/free-solid-svg-icons";
import { faPersonRunning } from "@fortawesome/free-solid-svg-icons";
import { faAtom } from "@fortawesome/free-solid-svg-icons";
import { faBookOpen } from "@fortawesome/free-solid-svg-icons";
import "./../../../styles/Service.css";

function Purpose() {
  return (
    <section
      className="page-transition portfolio bg-light transition"
      id="quote"
    >
      <div className="container">
        {/* <h2 className="page-transition-heading text-center text-uppercase text-primary mb-5">
          Loisirs
        </h2> */}
        <div className="row justify-content-center">
          <p className="fs-4 text-secondary">
            Mon objectif est de mettre mes compétences en informatique au
            service des autres en offrant des services et en créant des outils.
          </p>
        </div>
      </div>
    </section>
  );
}
export default Purpose;
