import "./../../../styles/Achievement.css";
import { Link } from "react-router-dom";
import img1 from "./../../../assets/images/projet/img1.jpg";
import img2 from "./../../../assets/images/projet/img2.jpg";
import img3 from "./../../../assets/images/projet/img3.jpg";
import img4 from "./../../../assets/images/projet/img4.jpg";
import img5 from "./../../../assets/images/projet/img5.jpg";
import img6 from "./../../../assets/images/projet/img6.jpg";
import AchievementCardModal from "../AchievementCardModal";
import { useState, useEffect } from "react";
import { portfolioData } from "../../../datas/portfolioData";

const AchievementList = [img1, img2, img3, img4, img5, img6];
function sizeGestion(index) {
  if (index === 0 || index === 1 || index === 2) {
    return "col-md-6 col-lg-4 mb-5";
  } else if (index === 3) {
    return "d-none d-md-block col-md-6 col-lg-4 mb-5 mb-lg-0";
  } else if (index === 4) {
    return "d-none d-lg-block col-lg-4 mb-5 mb-md-0";
  } else {
    return "d-none d-lg-block col-lg-4";
  }
}

function Achievement() {
  const [data, setData] = useState([]);
  const [currentRadio, setRadio] = useState(1);
  useEffect(() => {
    setData(portfolioData);
  }, [data]);
  return (
    <section className="page-section portfolio" id="portfolio">
      <div className="container">
        <h2 className="page-section-heading text-center text-uppercase text-primary mb-5">
          Réalisations
        </h2>
        {/* {`page-item ${currentPage === 1 ? "disabled" : ""}`} */}
        <div className="w-75 mb-4 text-center m-auto w-100 chooseTech row justify-content-center">
          <div
            className="btn-group btn-group-toggle row justify-content-center"
            data-toggle="buttons"
          >
            <input
              type="radio"
              className="btn-check"
              name="options"
              id="option1"
              autoComplete="off"
              checked={currentRadio === 1 ? true : false}
              onChange={() => setRadio(1)}
            />
            <label
              className="btn col-3 fw-bold"
              htmlFor="option1"
              id="option-php"
            >
              Projets
            </label>

            <input
              type="radio"
              className="btn-check"
              name="options"
              id="option2"
              autoComplete="off"
              checked={currentRadio === 2 ? true : false}
              onChange={() => setRadio(2)}
              disabled
            />
            <label className="btn col-3 fw-bold" htmlFor="option2">
              Articles
            </label>

            <input
              type="radio"
              className="btn-check"
              name="options"
              id="option4"
              autoComplete="off"
              checked={currentRadio === 3 ? true : false}
              onChange={() => setRadio(3)}
              disabled
            />
            <label
              className="btn col-3 fw-bold"
              htmlFor="option4"
              id="option-dart"
            >
              Autres
            </label>
          </div>
        </div>
        <div className="row justify-content-center">
          {data.map((value, index) => (
            <div className={sizeGestion(index)} key={`div-${index}`}>
              <p>{value.images[0].src}</p>
              <AchievementCardModal
                index={index}
                title={value.title}
                src={value.images[0].url}
                alt={value.images[0].alt}
                tech={value.technologies}
                dateCreation={value.dateCreation}
                link={value.link}
                description={value.generalDescription}
              />
            </div>
          ))}
        </div>
        <div className="text-center mt-2">
          <Link
            className="btn btn-outline-primary btn-lg mt-5 rounded-pill fw-bolder fs-6"
            to={"/achievement"}
          >
            Voir plus de réalisations
          </Link>
        </div>
      </div>
    </section>
  );
}
export default Achievement;
