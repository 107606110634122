import "./../../../styles/About.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import avataaars from "./../../../assets/images/avataaars.svg";
import {
  faDownload,
  faLaptopCode,
  faScrewdriverWrench,
  faRobot,
} from "@fortawesome/free-solid-svg-icons";

function About() {
  return (
    <section
      className="page-section text-white mb-0 bg-primary-dark"
      id="about"
    >
      <div className="container">
        <h2 className="page-section-heading text-center text-uppercase text-white mb-5">
          A propos
        </h2>
        <div className="row flex-column-reverse flex-sm-row">
          <div className="col-12">
            <p className="lead text-center text-light fs-4 fw-bolder">
              Si vous êtes à la recherche d'un développeur compétent et
              expérimenté, vous êtes au bon endroit !
            </p>
            <p className="lead text-center text-light">
              Je suis passionné par la création de <strong>sites web </strong>qui répondent aux
              besoins de mes clients et qui offrent une expérience utilisateur
              optimale. Avec plusieurs années d'expérience dans le <strong>développement
              web</strong>, j'ai acquis des compétences solides en matière de
              programmation, de conception et de gestion de projets.
            </p>
          </div>
          <div className="col-12 col-md-4 mb-5 text-center">
            <img className="about-photo" src={avataaars} alt="andy faussau" />
          </div>
          <div className="col-12 col-md-8 d-none d-md-block">
            <div className="lead d-flex h-100 align-items-center justify-content-between">
              <ul className="list-unstyled fs-4">
                <li className="mb-3">
                  <strong>Age :</strong> 28 ans
                </li>
                <li className="mb-3">
                  <strong>Métier :</strong> Développeur web
                </li>
                <li className="mb-3">
                  <strong>Statut :</strong> Salarié
                </li>
                {/* <li className="mb-3">
                  <strong>Permis :</strong> Oui
                </li> */}
              </ul>
              <ul className="list-unstyled fs-4">
                <li className="mb-3">
                  <strong>Lieu :</strong> Noisy-le-grand, Ile-de-France
                </li>
                <li className="mb-3">
                  <strong>Expérience :</strong> 4 ans
                </li>
                <li className="mb-3">
                  <strong>Disponibilité :</strong> En poste
                </li>
                {/* <li className="mb-3">
                  <strong>Imperdiet :</strong> Phasellus at urna
                </li> */}
              </ul>
            </div>
          </div>
          <div className="col-4 d-none d-lg-block m-0 p-0">
            <div className="card rounded-0 rounded-start text-dark text-center h-100">
              <div className="card-body">
                <span className="btn btn-info text-light my-3 rounded-circle">
                  <FontAwesomeIcon className="fs-1" icon={faLaptopCode} />
                </span>
                <h5 className="card-title">Développeur Full Stack</h5>
                <p className="card-text text-secondary">
                  Je suis capable de concevoir et de développer des <strong>sites web </strong>
                  complets, de l'interface utilisateur à la gestion des données
                </p>
                <p className="mb-0 mt-5 card-text text-secondary">
                  <span className="fw-bolder text-danger tech">
                    Technolgies
                  </span>{" "}
                  <br />
                  PHP, Java, JavaScript, Symfony, Spring, React js
                </p>
              </div>
            </div>
          </div>
          <div className="col-4 d-none d-lg-block m-0 p-0">
            <div className="card rounded-0 rounded-0 text-dark text-center h-100">
              <div className="card-body">
                <span className="btn btn-info text-light my-3 rounded-circle">
                  <FontAwesomeIcon
                    className="fs-1"
                    icon={faScrewdriverWrench}
                  />
                </span>
                <h5 className="card-title">Créateur d'application</h5>
                <p className="card-text text-secondary">
                  Je prends régulièrement plaisir à concevoir de petites 
                  <em> applications</em> avec <em>Flutter</em> (une des rares personnes en
                  France à l'utiliser)
                </p>
                <p className="mb-0 mt-5 card-text text-secondary">
                  <span className="fw-bolder text-danger tech">
                    Technolgies
                  </span>
                  <br />
                  Dart, Flutter
                </p>
              </div>
            </div>
          </div>
          <div className="col-4 d-none d-lg-block m-0 p-0">
            <div className="card rounded-0 rounded-end h-100 text-dark text-center">
              <div className="card-body">
                <span className="btn btn-info text-light my-3 rounded-circle">
                  <FontAwesomeIcon className="fs-1" icon={faRobot} />
                </span>
                <h5 className="card-title">Passionné de technologies</h5>
                <p className="card-text text-secondary">
                  J'ai également une grande passion pour l'exploration de
                  nouvelles technologies et des tendances émergentes
                </p>
                <p className="mb-0 mt-5 card-text text-secondary">
                  <span className="fw-bolder text-danger tech">
                    Technolgies
                  </span>
                  <br />
                  Chat GPT, Google Fuchsia,...
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mt-2">
          <a
            className="btn btn-lg mt-5 text-primary btn-light rounded-pill fw-bolder"
            href="https://startbootstrap.com/theme/freelancer/"
          >
            <FontAwesomeIcon icon={faDownload} />{" "}
            <span className="fs-6">Télécharger mon CV</span>
          </a>
        </div>
      </div>
    </section>
  );
}
export default About;
