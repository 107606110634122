import Header from "./components/header";
import Footer from "./components/footer";
import "./../styles/Blog.css";
import { useEffect, useState } from "react";
import AchievementCard from "./components/AchievementCard";
import axios from "axios";
import { Link } from "react-router-dom";
import SearchWidget from "./components/searchWidget";
import CategoryWidget from "./components/categoryWidget";
import SideWidget from "./components/sideWidget";
import { portfolioData } from "../datas/portfolioData";

function Achievement() {
  const [data, setData] = useState([]);
  const [currentPage, setPage] = useState(1);
  const [numberPage, setnumberPage] = useState(1);
  useEffect(() => {
    document.title = "Mes réalisations - Andy Faussau";
  }, []);
  const pageDecrement = () => {
    setPage((prevCount) => prevCount - 1); // Enlève 1 à la valeur actuelle de count
  };
  const pageIncrement = () => {
    setPage((prevCount) => prevCount + 1); // Enlève 1 à la valeur actuelle de count
  };

  useEffect(() => {
    // axios
    //   .get("http://127.0.0.1:8000/api/achievements?page=" + currentPage)
    //   .then((response) => {
    //     const hydraCollection = response.data;
    //     const items = hydraCollection["hydra:member"];
    //     setnumberPage(Math.ceil(hydraCollection["hydra:totalItems"] / 6));
    //     setData(items);
    //   })
    //   .catch((error) => console.error(error));

    setData(portfolioData);
  }, [currentPage]);

  function paginationRender(numberPage) {
    const items = [];
    for (let i = 1; i <= numberPage; i++) {
      items.push(
        <li
          key={"pagination-" + i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <Link className="page-link" onClick={() => setPage(i)}>
            {i}
          </Link>
        </li>
      );
    }
    return <>{items}</>;
  }

  return (
    <div>
      <Header />
      <div className="py-5 bg-light border-bottom mb-4">
        <div className="container">
          <div className="text-center my-5">
            <h1 className="fw-bolder text-primary">
              Voici toutes mes réalisations
            </h1>
            <p className="lead mb-0">
              Bienvenue sur ma page de réalisations ! Vous trouverez ici tous
              mes projets réalisés et ceux à venir
            </p>
          </div>
        </div>
      </div>
      <div className="container blog">
        <div className="row">
          <div className="col-lg-8">
            <div className="row">
              {data.map((value, index) => (
                <div key={index} className="mb-4 col-lg-6">
                  <AchievementCard
                    id={value.id}
                    title={value.title}
                    desc={value.shortDescription}
                    src={value.images[0].url}
                    tech={value.technologies}
                  />
                </div>
              ))}
            </div>
            <nav aria-label="Pagination">
              <hr className="my-0" />
              <ul className="pagination justify-content-center my-4">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <Link className="page-link" onClick={pageDecrement}>
                    Précédent
                  </Link>
                </li>
                {paginationRender(numberPage)}

                {/* 
                <li className={`page-item ${currentPage === 1 ? "active" : ""}`}>
                  <Link className="page-link link-primary" onClick={() => setPage(1)}>1</Link>
                </li>
                <li
                  className={`page-item ${currentPage === 2 ? "active" : ""}`}
                >
                  <Link
                    className="page-link link-primary"
                    onClick={() => setPage(2)}
                  >
                    2
                  </Link>
                </li>
                <li
                  className={`page-item ${currentPage === 3 ? "active" : ""}`}
                >
                  <Link
                    className="page-link link-primary"
                    onClick={() => setPage(3)}
                  >
                    3
                  </Link>
                </li>

                <li className="page-item disabled">
                  <a className="page-link" href="#!">
                    ...
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link link-primary" href="#!">
                    15
                  </a>
                </li> */}
                <li
                  className={`page-item ${
                    currentPage === numberPage ? "disabled" : ""
                  }`}
                >
                  <Link className="page-link" onClick={pageIncrement}>
                    Suivant
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="col-lg-4">
            {/* <SearchWidget />
            <CategoryWidget /> */}
            <SideWidget />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Achievement;
