import "./../../../styles/Contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF,faTwitter,faLinkedinIn,faInstagram,faGithub } from '@fortawesome/free-brands-svg-icons';
import { faHouse, faPhone,faEnvelope } from '@fortawesome/free-solid-svg-icons';
import accueilImg from "./../../../assets/images/contact.jpg";
import { useState, useEffect } from 'react'
import { Button } from "react-bootstrap";
function MyComponent(props) {
  const { value } = props;

  switch (value) {
    case '1':
      return <div>Option 1 sélectionnée</div>;
    case '2':
      return <div>Option 2 sélectionnée</div>;
    case '3':
      return <div>Option 3 sélectionnée</div>;
    default:
      return <div>Aucune option sélectionnée</div>;
  }
}
function Contact() {
const [isSent, handleSubmit] = useState(false);
const [lie, noClick] = useState("");
const [selectedOption, setSelectedOption] = useState('');
const handleChange = (event) => {
  setSelectedOption(event.target.value);
};
// console.log(selectedOption);
//   useEffect(() => {
//     const timeoutId = setTimeout(() => {
//       noClick("");
//     }, 5000);

//     return () => {
//       clearTimeout(timeoutId);
//     };
// },[lie]);
  return (
    <section className="page-section" id="contact" style={{ backgroundImage: `url(${accueilImg})` }}>
      <div className="container">
        <h2 className="page-section-heading text-center text-uppercase text-white mb-5">
          Contactez-moi
        </h2>
        <div className="row">
          <div className="col-lg-4">
            <div className="card rounded-0 shadow-sm">
              <div className="card-body d-flex flex-column justify-content-center">
                <div className="d-flex contactDetail mb-4">
                  <FontAwesomeIcon className="mx-2 contactIcon text-primary" icon={faPhone} />
                  <div>
                    <a href="tel:+33659013413" className="text-decoration-none">
                      <h4 className="m-0 link-dark">Telephone :</h4>
                      <p className="m-0">06 59 01 34 14</p>
                    </a>
                  </div>
                </div>
                <div className="d-flex contactDetail mb-4">
                  <FontAwesomeIcon className="mx-2 contactIcon text-primary" icon={faEnvelope} />
                  <div>
                    <a href="mailto:andyfaussau@gmail.com text-decoration-none" className="text-decoration-none">
                      <h4 className="m-0 link-dark">Mail :</h4>
                      <p className="m-0">andyfaussau@gmail.com</p>
                    </a>
                  </div>
                </div>
                <div className="d-flex contactDetail mb-4">
                  <FontAwesomeIcon className="mx-2 contactIcon text-primary" icon={faHouse} />
                <div>     
                  <h4 className="m-0">Lieu :</h4>
                  <p className="m-0">Noisy-le-grand</p>
                </div>
              </div>
              {/* <div className="mt-5 text-center">
                <a className="btn btn-outline-primary btn-social mx-1" href="#!">
                  <FontAwesomeIcon icon={faFacebookF} /></a>
                <a className="btn btn-outline-primary btn-social mx-1" href="#!">
                  <FontAwesomeIcon icon={faTwitter} />            
                </a>
                <a className="btn btn-outline-primary btn-social mx-1" href="#!">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
                <a className="btn btn-outline-primary btn-social mx-1" href="#!">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a className="btn btn-outline-primary btn-social mx-1" href="#!">
                <FontAwesomeIcon icon={faGithub} />
                </a>
              </div> */}
            </div>
            </div>
          </div>
          <div className="d-none d-md-block mt-md-3 mt-lg-0 col-lg-8 mr-2">
            <div className="card rounded-0 shadow-sm">
              {
                isSent ?
                <div className="card-body d-flex">
                  <div className="m-auto text-center">
                    <p className="situation fs-3 text-success">
                   {/* <FontAwesomeIcon icon={faCheck} /> */} Votre commentaire <strong>n'a pas</strong> été pris en compte !
                    </p>
                    <p className="text-secondary">
                        Le formulaire est décoratif...
                    </p>
                  </div>
                </div>
              :
              <div className="card-body d-flex flex-column justify-content-between">
                <p className="card-text situation fs-5">
                  Pour plus de renseignements, n'hésitez pas à me contacter !
                </p>
                <form onSubmit={handleSubmit}>
                  <div class="mb-3">
                    <label htmlFor="exampleFormControlInput2" className="form-label">Nom</label>
                    <input type="text" className="form-control" id="exampleFormControlInput2"/>
                  </div>
                  <div class="mb-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Adresse mail</label>
                    <input type="email" className="form-control" id="exampleFormControlInput1"/>
                  </div>
                  {/* <div className="mb-3">
                    <label htmlFor="selectOption" className="form-label">Que désirez-vous ?</label>
                    <select className="form-select" name="selectOption" id="selectOption" value={selectedOption} onChange={handleChange}>
                      <option></option>
                      <option value="1">Collaboration</option>
                      <option value="2">Demande de renseignement</option>
                      <option value="3">Feedback</option>
                      <option value="4">Proposition de projet</option>
                    </select>
                  </div> */}
                  {/* <div className="mb-3">
                    <label htmlFor="question" className="form-label d-block">Quel type de collaboration souhaitez-vous ?</label>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"/>
                      <label className="form-check-label" htmlFor="inlineCheckbox1">Contrat salarié</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
                      <label className="form-check-label" htmlFor="inlineCheckbox2">Contrat freelance</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="checkbox" id="inlineCheckbox3" value="option3"/>
                      <label className="form-check-label" htmlFor="inlineCheckbox3">Autres</label>
                    </div>
                  </div> */}
                  <div className="mb-3">
                    <label htmlFor="exampleFormControlTextarea1" className="form-label">Votre message</label>
                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" required></textarea>
                  </div>
                  <div className="mb-3">
                    <Button className="me-2" variant="primary" type="submit">Envoyer</Button>
                    <Button variant="danger" type="reset">Effacer</Button>
                  </div>
                  {/* <p className="text-secondary"><em>Le formulaire est décoratif, ne perdez pas votre temps à l'alimenter</em></p> */}
                </form>
              </div>
            }

            </div>            
          </div>
        </div>
      </div>
    </section>
  );
}
export default Contact;
