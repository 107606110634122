import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "./../../styles/AchievementCardModal.css";
import { Link } from "react-router-dom";
function AchievementCardModal({
  index,
  src,
  alt,
  title,
  tech,
  dateCreation,
  link,
  description,
}) {
  return (
    <>
      <div
        className="portfolio-item mx-auto rounded justify-content-end flex-column d-flex"
        data-bs-toggle="modal"
        data-bs-target={"#portfolioModal" + index}
        style={{ backgroundImage: `url(${src})` }}
      >
        <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
          <div className="portfolio-item-caption-content h-100 text-center text-light d-flex justify-content-between flex-column">
            <h4 className="fs-6 mt-1">{title}</h4>
            <FontAwesomeIcon className="fs-2" icon={faPlus} />
            <div className="text-center fs-6 mb-1">
              {tech.map((value, index) => (
                <span className="badge bg-light text-primary m-1">
                  {value.tech}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        key={`modal-${index}`}
        className="portfolio-modal modal fade"
        id={"portfolioModal" + index}
        tabIndex="-1"
        aria-labelledby={"portfolioModal" + index}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <button
                className="btn-close"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-center">
              <div className="container">
                <div className="row justify-content-center text-start">
                  <h2 className="portfolio-modal-title text-primary text-center text-uppercase mb-4">
                    {title}
                  </h2>
                  <div className="col-lg-8">
                    <img
                      className="img-fluid rounded mb-2"
                      src={src}
                      alt={alt}
                    />
                  </div>
                  <div className="col-lg-4">
                    <p className="fs-3 d-none d-lg-block">Technologies :</p>
                    <div className="mb-2">
                      {tech.map((value, index) => (
                        <span
                          key={index}
                          className="badge bg-primary text-white me-1 mb-2 fs-6 fw-normal"
                        >
                          {value.tech}
                        </span>
                      ))}
                    </div>
                    <p className="d-none d-lg-block fs-6 mb-2">
                      Date de création : {dateCreation}
                    </p>
                    {/* <p className="d-none d-lg-block fs-6 mb-2">
                      Dernière mise à jour : 12/12/2023
                    </p> */}
                    <div className="d-lg-block fs-6 mb-2">
                      Lien :
                      <ul className="list-unstyled">
                        {link.map((value, index) => (
                          <li>
                            <Link className="text-decoration-none" target="_blank"
                              rel="noreferrer"
                              to={value.link}
                            >
                              {" "}
                              {value.type + " "}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                    {/* <p className="mt-4 d-none d-lg-block">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Mollitia neque assumenda ipsam nihil, molestias magnam,
                      recusandae quos quis inventore quisquam velit asperiores,
                      vitae? Reprehenderit soluta, eos quod consequuntur itaque.
                      Nam.
                    </p> */}
                  </div>
                  <div className="col-lg-12">
                    <p className="mb-4">{description}</p>
                  </div>
                  <div className="col-lg-12 text-center">
                    <button className="btn btn-primary" data-bs-dismiss="modal">
                      <i className="fas fa-times fa-fw"></i> Fermer
                    </button>
                  </div>
                  {/* <button className="btn btn-danger" data-bs-dismiss="modal">
                        <i className="fas fa-times fa-fw"></i> Close Window
                      </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AchievementCardModal;
