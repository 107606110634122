import "./../../../styles/Masthead.css";
import accueilImg from "./../../../assets/images/accueil.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronDown } from "@fortawesome/free-solid-svg-icons";
// import { Link } from "react-router-dom";

var fakeApi = Math.floor(Math.random() * 6);
var fakeApiPlace = Math.floor(Math.random() * 4);
var fakeApiStatus = Math.floor(Math.random() * 2);
var fakeApiClient = Math.floor(Math.random() * 4);

function Masthead() {
  return (
    <header
      className="masthead text-primary text-center d-flex flex-column"
      style={{ backgroundImage: `url(${accueilImg})` }}
    >
      <nav className="navbar navbar-expand-lg navbar-transparent bg-transparent">
        <div className="container">
          {/* <a className="navbar-brand link-primary" href="#!">Andy Faussau</a> */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {/* <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link link-primary active" to={"/"}>
                  Accueil
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link link-primary" to={"/blog"}>
                  Blog
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link link-primary" to={"/achievement"}>
                  Réalisations
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link link-primary" to={"/admin"}>
                  Admin
                </Link>
              </li>
            </ul>
          </div> */}
        </div>
      </nav>
      <div className="container d-flex flex-column justify-content-center m-auto">
        <h1 className="masthead-heading my-4">
          <span className="text-uppercase">Faussau</span> Andy
        </h1>
        <p className="masthead-subheading text-primary-dark fw-bold fs-2 mb-2">
          Développeur web{" "}
          {/* <span className="d-none d-md-inline"> - Freelance</span> */}
        </p>
        <p className="lead mb-2 text-center pt-4 pb-5 fs-45 fw-bold">
          Bonjour et bienvenue sur mon <strong>portfolio</strong>
          <span className="d-none d-md-inline">
          , où vous
          pourrez découvrir mes projets et compétences en matière de conception
          et de développement de <strong>sites web </strong> modernes et performants
          </span>
        </p>
        <p className="text-center pt-3">
          <a className="link-primary" href="#about">
            <FontAwesomeIcon icon={faCircleChevronDown} />
          </a>
        </p>
      </div>
    </header>
  );
}
export default Masthead;

const availabilityTest = [
  "custom",
  "mission",
  "holiday",
  "achievement",
  "article",
  "other",
];
const placeTest = ["", "Suède", "Uruguay", "Corée du Sud"];
const statusTest = ["update", "creation"];
const clientTest = ["", "Total energie", "Google", "LVMH"];
function availability(value, endDate, client, lieu, sujet, status) {
  switch (value) {
    case "custom":
      return "Ici une phrase personnalisé";
    case "mission":
      if (client !== null && client !== "") {
        return "Je suis en mission chez " + client + " juqu'au " + endDate;
      } else {
        return "Je suis en mission jusqu'au " + endDate;
      }
    case "holiday":
      if (lieu !== null && lieu !== "") {
        return "Je suis en vacance en " + lieu + " jusqu'au " + endDate;
      } else {
        return "Je suis en vacance jusqu'au " + endDate;
      }
    case "achievement":
      if (status === "update") {
        return "L'application " + sujet + " a été mise à jour";
      } else if (status === "corrected") {
        return "L'application " + sujet + " a été corrigé";
      } else {
        return "L'application " + sujet + " est terminé";
      }
    case "article":
      if (status === "update") {
        return "L'article : " + sujet + " a été mise à jour";
      } else {
        return "Un nouvelle article : " + sujet + " a été ajouté";
      }
    default:
      return "Lorem ipsum dolor sit amet";
  }
}

function commentary(value) {
  if (value === "mission" || value === "holiday") {
    const test = [
      "désolé, je sais que c'est dur mais vous y survivrez",
      "si vous avez des idées de phrase, n'hésitez pas à me le dire",
      "duis bibendum commodo risus non mattis",
      "ut vitae vulputate dui, a volutpat ipsum",
      "nunc venenatis bibendum velit",
      "vel consectetur justo sagittis eu",
    ];
    return ", " + test[Math.floor(Math.random() * 6)];
  } else if (value === "achievement" || value === "article") {
    const test = [
      "je vous encourage vivement à jeter un coup d'oeil",
      "n'hésitez pas en me faire des critiques",
      "duis bibendum commodo risus non mattis",
      "ut vitae vulputate dui, a volutpat ipsum",
      "nunc venenatis bibendum velit",
      "vel consectetur justo sagittis eu",
    ];
    return ", " + test[Math.floor(Math.random() * 6)];
  }
}
