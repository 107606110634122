import React from "react";
import ReactDOM from "react-dom/client";
// Bootstrap CSS
// import "bootstrap/scss/bootstrap.scss";
// import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "./index.scss";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Home from "./pages/Home";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";
import Blog from "./pages/Blog";
import Achievement from "./pages/Achievement";
import Article from "./pages/Article";
import AchievementItem from "./pages/AchievementItem";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/achievement",
    element: <Achievement />,
  },
  {
    path: "/achievement/:id",
    element: <AchievementItem />,
  },
  // {
  //   path: "/blog",
  //   element: <Blog />,
  // },
  // {
  //   path: "/blog/:id",
  //   element: <Article />,
  // },
  // {
  //   path: "/portfolio",
  //   element: <Portfolio />,
  // },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
