import "./../../../styles/Service.css";

function BlogTransition() {
  return (
    <section
      className="page-transition portfolio bg-light transition"
      id="blogtransition"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-3 mb-0 number">
            <h4 className="fs-5 pb-2 ">
              <span className="text-info fs-2">12<br/></span> 
              <span className="text-primary description pb-1">Missions effecutées</span>
            </h4>
          </div>
          <div className="col-3 mb-0 number">
            <h4 className="fs-5 pb-2 ">
              <span className="text-info fs-2">1280<br/></span> 
              <span className="text-primary description pb-1">Lorem ipsum</span>
            </h4>
          </div>
          <div className="col-3 mb-0 number">
            <h4 className="fs-5 pb-2 ">
              <span className="text-info fs-2">36<br/></span> 
              <span className="text-primary description pb-1">Ipsum lorem</span>
            </h4>
          </div>
          <div className="col-3 mb-0 number">
            <h4 className="fs-5 pb-2 ">
              <span className="text-info fs-2">8<br/></span> 
              <span className="text-primary description pb-1">Projets réalisés</span>
            </h4>
          </div>
        </div>
        <p className=""></p>
      </div>
    </section>
  );
}
export default BlogTransition;
