import { Link } from "react-router-dom";
import "./../../styles/Header.css";

function Footer() {
  return (
    <footer className="py-5 bg-dark">
      <div className="container"><p className="m-0 text-center text-white">Copyright &copy; Andy FAUSSAU  2023</p></div>
    </footer>
  );
}

export default Footer;
