import Header from "./components/header";
import { useEffect } from "react";
import "./../styles/ErrorPage.css";
import { Link } from "react-router-dom";

function ErrorPage() {
  useEffect(() => {
    document.title = "Page introuvable";
  }, []);
  return (
    <>
      <Header />
      <div className="container errorPage">
        <h1 className="my-4 text-primary">ERREUR 404</h1>
        <p className="fs-1">
          Oops ! La page que vous recherchez est introuvable
        </p>
        <p className="text-secondary fs-5 mb-4 d-none d-md-block">
          Il est possible que la page ait été supprimée, que son nom ait été
          modifié ou qu'elle soit temporairement indisponible. Nous sommes
          désolés pour la gêne occasionnée. Vous pouvez retourner à la page
          d'accueil pour explorer d'autres sections de notre site ou utiliser la
          barre de recherche pour trouver ce que vous cherchez.
        </p>
        <div className="row">
          <div className="col-12 col-lg-4 mb-2">
            <Link className="text-decoration-none link-dark" to={"/"}>
              <div className="card shadow-sm border-0 bg-primary bg-opacity-50 h-100">
                <div className="card-body">
                  <h5 className="card-title">Accueil</h5>
                  <p className="card-text">
                    Pour retourner sur la page principale
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-12 col-lg-4 mb-2">
            <Link className="text-decoration-none link-dark" to={"/achievement"}>
              <div className="card shadow-sm border-0 bg-primary bg-opacity-50 h-100">
                <div className="card-body">
                  <h5 className="card-title">Realisation</h5>
                  <p className="card-text">
                    Pour voir toutes mes réalisations et mes projets en cours
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-12 col-lg-4 mb-2">
            <div className="card shadow-sm border-0 bg-secondary bg-opacity-50 h-100">
              <div className="card-body">
                <h5 className="card-title">Blog</h5>
                <p className="card-text">
                  Le blog sera crée prochainement
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ErrorPage;
