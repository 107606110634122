import About from "./components/portfolio-components/About";
import Masthead from "./components/portfolio-components/Masthead";
import "./../styles/Portfolio.css";
import Achievement from "./components/portfolio-components/Achievement";
import Contact from "./components/portfolio-components/Contact";
import NavPortfolio from "./components/portfolio-components/NavPortfolio";
import Service from "./components/portfolio-components/Service";
import BlogTransition from "./components/portfolio-components/BlogTransition";
import Skill from "./components/portfolio-components/Skill";
import Hobby from "./components/portfolio-components/hobby";
import Footer from "./components/footer";
import Career from "./components/portfolio-components/Career";
import Quote from "./components/portfolio-components/Purpose";
import Quote2 from "./components/portfolio-components/Quote2";
import { useEffect } from "react";

function Home() {
  useEffect(() => {
    document.title = "Andy Faussau - Développeur Web Java/Php - Noisy-le-grand";
  }, []);
  return (
    <>
      {/* <div className="row"> */}
      <NavPortfolio />
      <Masthead />
      <main>
        <About />
        <Quote />
        <Achievement />
        {/* <BlogTransition /> */}
        <Service />
        {/* <Quote2 /> */}
        <Skill />
        {/* <Quote /> */}
        {/* <Career /> */}
        {/* <Hobby /> */}
        <Contact />
      </main>
      <Footer />
      {/* </div> */}
    </>
  );
}
export default Home;
